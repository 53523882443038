import { createContext, useState, useEffect } from 'react';
import { MONTHS } from '../client/constans/constans';
import { getTasks } from '../client/service/TasksService';

export const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
    const [allTask, setAllTask] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('Все');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const formatDate = (date) => {
        return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    };

    const getRandomNumber = () => {
        return Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000 + '';
    };

    const nullTask = {
        id: getRandomNumber(),
        title: '',
        followers: [],
        tg: [],
        currentValue: 0,
        maxValue: 100000,
        timer: '23 ч 59 мин',
        startDate: '',
        endDate: '',
        service: 'Подписчики',
        speed: 1,
        gender: 'Любой',
        status: 'inProgress',
        activeCount: 1,
    };
    const [newTaskData, setNewTaskData] = useState({ ...nullTask });
    const [_taskFirstTime, setTaskFirstTime] = useState(formatDate(new Date()));
    const [_taskSecondTime, setTaskSecondTime] = useState('');

    const fetchAllTasks = async () => {
        try {
            setLoading(true);
            const response = await getTasks();
            setAllTask(response);
            filterTasks(selectedStatus, selectedOptions, searchQuery, response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('AccessToken');
        if (token) {
            fetchAllTasks();
        }
    }, []);

    const filterTasks = (status, types, query, tasks = allTask) => {
        let filtered = tasks.length > 0 ? [...tasks] : [];

        if (status !== 'Все' && filtered.length > 0) {
            if (status === 'В работе') {
                filtered = filtered.filter(task => task.count_done !== task.count);
            } else if (status === 'Пауза') {
                filtered = filtered.filter(task => task.status === 'paused');
            } else if (status === 'Готово') {
                filtered = filtered.filter(task => task.count_done === task.count);
            }
        }

        if (types && types.length > 0 && filtered.length > 0) {
            filtered = filtered.filter(task => types.includes(task.type));
        }

        if (query && filtered.length > 0) {
            filtered = filtered.filter(item =>
                item.id.toString().includes(query) || item.name.toLowerCase().includes(query.toLowerCase())
            );
        }

        setFilteredTasks(filtered);
    };

    const filterTasksByType = (types) => {
        setSelectedOptions(types);
        filterTasks(selectedStatus, types, searchQuery);
    };

    const updateFilterStatus = (status) => {
        setSelectedStatus(status);
        filterTasks(status, selectedOptions, searchQuery);
    };

    const searchTasks = (query) => {
        setSearchQuery(query);
        filterTasks(selectedStatus, selectedOptions, query);
    };

    return (
        <TaskContext.Provider value={{
            allTask,
            setAllTask,
            filteredTasks,
            setFilteredTasks,
            loading,
            newTaskData,
            setNewTaskData,
            setTaskFirstTime,
            setTaskSecondTime,
            fetchAllTasks,
            setSelectedOptions,
            setSelectedStatus,
            filterTasksByType,
            updateFilterStatus,
            filterTasks,
            searchTasks
        }}>
            {children}
        </TaskContext.Provider>
    );
};
