import {
    Route,
    Routes
  } from "react-router-dom";
import { lazy } from 'react'
import { NotFound } from "../components/UI/notFound/NotFound";
import { TaskProvider } from "../provider/TaskProvider";
import ScrollToTop from './../hooks/ScrollToTop';
import ProtectedProvider from "../provider/ProtectedProvider";

const Admin = lazy(() => import('../pages/admin/Admin')); 
const Auth = lazy(() => import('../components/auth/authComponent/Auth'));
const LetterSent = lazy(() => import('../components/auth/letterSent/LetterSent'));
const Login = lazy(() => import('../components/auth/login/Login'));
const Registration = lazy(() => import('../components/auth/registration/Registration'));
const ResetPassword = lazy(() => import('../components/auth/resetPassword/ResetPassword'));
const Poeboto = lazy(() => import('../pages/main/Poeboto'));


const RouterPoeboto = () => {
    return (
        <>
        <ScrollToTop />
        <Routes>
            <Route path='/' element={<Login />} />
                <Route path='/app' element={
                    <TaskProvider>
                        <Poeboto />
                    </TaskProvider>
                }/>
            <Route path='/registration' element={<Registration />} />
            <Route path='/reset_password' element={<ResetPassword />} />
            <Route path='/auth' element={<Auth />} />
            <Route path='/letter_sent' element={<LetterSent />} />
            <Route path='/admin' element={
                <ProtectedProvider>
                    <Admin /> 
                </ProtectedProvider>
            }/>
            <Route path='*' element={<NotFound />} />
        </Routes>
        </>
    );
};

export default RouterPoeboto;