import { getHeaders,handleError } from '../api/apiConfig';
import createApiClient from '../api/apiClient';

// Получить задачи
export const getTasks = async () => {
    try {
        const headers = await getHeaders(true);
        const api = createApiClient(headers);
        const response = await api.tasks.retrieve_tasks_get();
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

// Создать новую задачу
export const newTask = async (data) => {
    try {
        const headers = await getHeaders(true);
        const api = createApiClient(headers);
        const response = await api.tasks.create_tasks_post(data);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

// Удалить задачу
export const deleteTask = async (id) => {
    try {
        const headers = await getHeaders(true);
        const api = createApiClient(headers);
        const response = await api.tasks.delete_tasks_delete({ task_id: id });
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};