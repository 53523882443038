
import axios from 'axios';

const API_BASE_URL = 'https://poeboto.ru/api';

const createApiClient = (headers = {}) => {
  const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      ...headers,
    },
  });

  return {
    default: {
      get_page_channel_get_page_channel_get: (url) => client.get(`/get_page_channel/`, { params: url })
    },
    users: {
      retrieve_users_get: () => client.get(`/users/`)
    },
    auth: {
      registration_auth_email_registration_post: (data) => client.post(`/auth/email/registration/`, data),
      token_auth_email_token_post: (data) => client.post(`/auth/email/token/`, data),
    },
    bots: {
      counters_bots_counters_get: () => client.get(`/bots/counters/`),
      retrieve_bots_retrieve_get: () => client.get(`/bots/retrieve/`),
      upload_bots_upload_zip_post: (zip_file) => {
        const formData = new FormData();
        formData.append('zip_file', zip_file);
        return client.post(`/bots/upload/zip/`, formData );
      },
      reraise_bots_reraise_get: (dead_raise) => client.get(`/bots/reraise/`, { params: dead_raise  })
    },
    tasks: {
      create_tasks_post: (data) => client.post(`/tasks/`, data),
      retrieve_tasks_get: () => client.get(`/tasks/`),  
      delete_tasks_delete: (task_id) => client.delete(`/tasks/`, { params: task_id }),
    }
  };
};

export default createApiClient;
