import './notFound.css'
import { useNavigate } from 'react-router-dom'

export const NotFound = () =>{
    const navigate = useNavigate();
    const handleBtn = ({target}) => {
        console.log(target.textContent);
        if(target.textContent === 'На главную') navigate('/app')
        else if(target.textContent === 'Назад') navigate(-1)
    }
    return(
    <div class="wrapper">
        <div class="container_found" data-text="404">
            <div class="title glitch" data-text="404">
                404
            </div>
            <div class="description glitch" data-text="PAGE NOT FOUND">
                PAGE NOT FOUND
            </div>
        </div>
        <div className="notFound__btns">
            <button className='notFound__btn' onClick={handleBtn}>На главную</button>    
            <button className='notFound__btn' onClick={handleBtn}>Назад</button>
        </div>  
    </div>
    )
}