import { useState } from 'react';

export const useModalState = () => {
    const [openOptions, setOpenOptions] = useState(false);
    const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
    const [modalNewTaskAdded, setModalNewTaskAdded] = useState(false);
    const [modalBalance, setModalBalance] = useState(false);

    return {
        openOptions, setOpenOptions,
        openNewTaskModal, setOpenNewTaskModal,
        modalNewTaskAdded, setModalNewTaskAdded,
        modalBalance, setModalBalance
    };
};
