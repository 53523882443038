import './Loader.css';
const Loader = () =>{
    return (
    <div className="loader__container">
        <div className="center">
            <span className='loader__square'></span>
            <span className='loader__square'></span>
            <span className='loader__square'></span>
            <span className='loader__square'></span>
        </div>
    </div>
    )
}

export default Loader;