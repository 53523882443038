import imgcry from './../../img/new_task/emojies/cry.png';
import imgmindblow from './../../img/new_task/emojies/mindblow.png';
import imgpoop from './../../img/new_task/emojies/poop.png';
import imgstars from './../../img/new_task/emojies/stars.png';
import imgthumbup from './../../img/new_task/emojies/thumb_up.png';
import imgthumbdown from './../../img/new_task/emojies/thumb_down.png';
import imgsmile from './../../img/new_task/emojies/smile.png';

import c1 from './../../img/new_task/1.svg';
import c3 from './../../img/new_task/3.svg';
import c4 from './../../img/new_task/4.svg';
import c2 from './../../img/new_task/2.svg';
import c5 from './../../img/new_task/5.svg';
import c6 from "./../../img/new_task/6.svg";
import c7 from "./../../img/new_task/7.svg";
import c8 from "./../../img/new_task/8.svg";

export const MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

export const HELP_TEXT = 'Настройте один или несколько этапов, чтобы получить разное количестко в разное время' 

export const SERVICES = [
    {name: 'followers', img: c1, label: 'Подписчики', secondLabel: 'подписчиков', minPrice: 'от 3,9 ₽'},
    {name: 'views', img: c3, label: 'Просмотры', secondLabel: 'просмотров', minPrice: '0,09 ₽'},
    {name: 'autoViews', img: c2, label: 'Авто просмотры', secondLabel: 'авто просмотров', minPrice: 'от 3,9 ₽'},
    {name: 'reactions', img: c4, label: 'Реакции', secondLabel: 'реакций', minPrice: 'от 1,2 ₽'},
    {name: 'autoReactions', img: c5, label: 'Авто реакции', secondLabel: 'авто реакций', minPrice: 'от 1,2 ₽'},
    {name: 'vote', img: c6, label: 'Голосование', secondLabel: 'голосований', minPrice: '0,9 ₽'},
    {name: 'comments', img: c7, label: 'Комментарии', secondLabel: 'комментариев', minPrice: 'от 4,9 ₽'},
    {name: 'gif', img: c8, label: 'Гив / реклама', secondLabel: 'гив / реклам', minPrice: 'от 0,09 ₽'},
];

export const SERVICE_TO_TYPE = {
    0: 'Просмотры',
    1: 'Реакции',
    2: 'Подписчики',
    3: 'Голосование',
    4: 'Комментарии',
    5: 'Отписки',
    6: 'Авто просмотры',
    7: 'Авто реакции',
};

export const TG_LINK_HELP = 'Можете вставлять ссылки по очереди или все сразу через пробел.';

export const TG_LINK_TITLE = ['Ссылки', 'Ссылки на посты', 'Ссылки на каналы'];

export const REACTIONS_TEXT = {
    title: 'Реакции',
    help: 'Отображаются только те, которые доступны в канале / группе',
    listTitle: 'Соотношение реакций',
    listHelp: 'Кол-во каждого вида реакций'
};

export const AUTO = {
    title: 'Авто просмотры',
    help: 'Укажите максимальное кол-во постов за день'
};

export const COMMENTS = {
    title: 'Комментарии',
    help: 'Укажите максимальное кол-во постов за день'
};

export const GIF = {
    title: 'Кол-во последних постов',
    help: 'На какое количество последних постов пойдут просмотры и реакции',
    views: ['Разброс просмотров', 'Разброс автопросмотров'],
    sections: [
        { title: 'Подписчики', img: c1, isOpen: true,isFollow:true },
        { title: 'Просмотры', img: c3, isOpen: true,isFollow:false },
        { title: 'Реакции', img: c4, isOpen: true,isFollow:false },
        { title: 'Автопросмотры', img: c2, isOpen: true,isFollow:false },
        { title: 'Автореакции', img: c5, isOpen: true ,isFollow:false}
    ]
};

export const EMOJIES_IMG = [
    { name: 'cry', img: imgcry },
    { name: 'mindblow', img: imgmindblow },
    { name: 'poop', img: imgpoop },
    { name: 'stars', img: imgstars },
    { name: 'thumbup', img: imgthumbup },
    { name: 'thumbdown', img: imgthumbdown },
    { name: 'smile', img: imgsmile }
];

export const EMOJI_MAP = {
    [imgcry]: '😢',
    [imgmindblow]: '🤯',
    [imgpoop]: '💩',
    [imgstars]: '🤩',
    [imgthumbup]: '👍',
    [imgthumbdown]: '👎',
    [imgsmile]: '😊'
};
