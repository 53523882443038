import './App.css';
import {useEffect,Suspense } from 'react';
import RouterPoeboto from './routes/RouterPoeboto';
import Context from './context/Context';
import {RecoilRoot} from "recoil";
import Loader from './components/UI/loader/Loader.jsx'
import { useUserData } from './hooks/useUserData';
import { useModalState } from './hooks/useModalState';
function App() {

    const {
        user, setUser, fetchUserData
    } = useUserData();

    const {
        openOptions, setOpenOptions, openNewTaskModal, setOpenNewTaskModal, modalNewTaskAdded, 
        setModalNewTaskAdded,modalBalance, setModalBalance
    } = useModalState();
    
    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const contextData = {
        user, setUser, openOptions, setOpenOptions, 
        openNewTaskModal, setOpenNewTaskModal,
        modalNewTaskAdded, setModalNewTaskAdded,
        modalBalance, setModalBalance
    };


    return (
        <Suspense fallback={<Loader />}>
            <RecoilRoot>
                <Context.Provider value={contextData}>
                    <div className="App">
                        <RouterPoeboto/>
                    </div>
                </Context.Provider>
            </RecoilRoot>
        </Suspense>
    );
}

export default App;
