//import { refreshShortToken } from "./UserService";
import { toast } from 'react-toastify';

export const API_V1_STR = 'https://poeboto.ru/api';


export const getHeaders = async (isJson = true, isForm = false, isMulti=false) => {
    const authToken = localStorage.getItem('AccessToken');
    const headers = {
        'access-token': authToken
    };

    if (isJson) headers['Content-Type'] = 'application/json';
    if (isForm) headers['Content-Type'] = 'application/x-www-form-urlencoded';
    if (isMulti) headers['Content-Type'] = 'multipart/form-data';
    return headers;
};

//Обработка ошибок запроса
export const handleError = error => {
    const errorResponse = error?.response?.data;
    toast.error(errorResponse?.detail || 'Произошла ошибка', {
        position: 'top-right',
        autoClose: 3000,
        theme: 'dark',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
    });
    return errorResponse;
};