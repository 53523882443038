import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../client/service/UserService';
import avatar from '../img/mobile_header/user_avatar.jpg';
export const useUserData = () => {
    const [user, setUser] = useState({
        avatar: avatar,
        username: "poeboto@poeboto.ru",
        verified: false,
        balance: 0,
    });
    const navigate = useNavigate();

    const fetchUserData = useCallback(async () => {
        const currentUrl = window.location.pathname;
        const userInfo = await getUserData();
        if (!userInfo.detail) {
            setUser({
                avatar: avatar,
                username: userInfo.email,
                verified: false,
                //balance: userInfo.balance ?? 100000,
                balance: 100000,
                is_superuser:userInfo.is_superuser
            });
        } else if (currentUrl === '/app') {
            navigate("/");
        }
    }, [navigate]);

    return { user, setUser, fetchUserData };
};
