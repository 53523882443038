import { getHeaders,handleError } from '../api/apiConfig';
import createApiClient from './../api/apiClient';

// Получить токен аутентификации
export const signIn = async (email, password) => {
    try {
        const headers = await getHeaders(true);
        const api = createApiClient(headers);
        const response = await api.auth.token_auth_email_token_post({ email, password });
        if (response.data.token) {
            localStorage.setItem('AccessToken', response.data.token);
        }
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

// Создать нового пользователя
export const signUp = async (email, password) => {
    try {
        const headers = await getHeaders(true);
        const api = createApiClient(headers);
        console.log(headers);
        const response = await api.auth.registration_auth_email_registration_post({ email, password });
        return response;
    } catch (error) {
        console.log(error);
        return handleError(error);
    }
};

// Получить информацию о пользователе
export const getUserData = async () => {
    try {
        const headers = await getHeaders(true);
        const api = createApiClient(headers);
        const response = await api.users.retrieve_users_get();
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};